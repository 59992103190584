@font-face {
  font-family: ShentoxBold;
  src: url(./fonts/Shentox_Bold.otf);
}
.icon{
  width: 64px;
  height: 64px;
  margin: 0.5em;
}
.icontext {
  color: white;
  margin-top: 0.6em;
  font-family: ShentoxBold;
}
.App {
  background-image: url(./warehouse.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
}
.topbar {
  background-color: rgb(32 32 32 / 90%);
  height: 5em;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.topbaricon {
  display: flex;
}
.topbarbuttons {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 50%;
  transform: translateX(50%);
}
.topbartext {
  color: white;
  margin-top: 0.6em;
  margin-right: 0.5em;
  margin-left: 0.5em;
  font-family: ShentoxBold;
}
.content {
  display: flex;
  justify-content: center;
  border-top: 2em;
  max-height: 100%;
  overflow-y: auto;
}
.p1 {
  display: flex;
  height: 8em;
  width: 100%;
  background-color: rgb(51, 51, 51);
  background: #000000a6;
  justify-content: center;
  align-items: center;
}
.p2 {
  display: flex;
  height: 6em;
  width: 100%;
  background-color: rgb(116, 115, 115);
  background: #00000070;
  justify-content: center;
  align-items: center;
}
.t1 {
  font-family: ShentoxBold;
  color: rgb(255, 255, 255);
  font-size: 3em;
}
.run {
  display: flex;
  padding: 20px;
  background-color: rgb(32 32 32 / 95%);
  margin: 0.4em;
  border-radius: 20px;
}
.runlist {
  overflow-wrap: anywhere;
}
.runs {
 width: 75%;
}
.link {
  text-decoration: none;
}
.text {
  font-family: ShentoxBold;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1em;
  margin-right: 1em;
}
.under {
  height: 90vh;
  width: 100%;
  align-items: center;
  display: flex;
}
